@media screen and (max-width: 767px) and (min-width: 300px) {
  .universalh3 {
    font-size: 1.4rem !important;
    line-height: 1.9rem !important; }
  .multipleTitle h1 {
    font-size: 5rem !important; }
  .universalSilverh1 {
    display: none; } }

html,
body {
  overflow-x: hidden !important;
  font-family: 'Barlow', sans-serif !important; }

@media screen and (min-width: 1921px) {
  html,
  body {
    font-size: 20px; } }

@media screen and (max-width: 1920px) and (min-width: 1681px) {
  html,
  body {
    font-size: 20px; } }

@media screen and (max-width: 1680px) and (min-width: 1600px) {
  html,
  body {
    font-size: 20px; } }

@media screen and (max-width: 1599px) and (min-width: 1360px) {
  html,
  body {
    font-size: 18px; } }

@media screen and (max-width: 1359px) and (min-width: 1024px) {
  html,
  body {
    font-size: 16px; } }

@media screen and (max-width: 1023px) and (min-width: 768px) {
  html,
  body {
    font-size: 15px; } }

@media screen and (max-width: 767px) and (min-width: 320px) {
  html,
  body {
    font-size: 14px; } }

.btn,
button,
Input,
input,
select {
  outline: none; }
  .btn:focus,
  button:focus,
  Input:focus,
  input:focus,
  select:focus {
    box-shadow: none;
    -webkit-box-shadow: none;
    outline: none; }

.universalSilverh1 {
  font-size: 4.5rem;
  font-weight: 800;
  color: #F6F6F6;
  margin: 0; }

.universalh3 {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2.3rem;
  color: #222222;
  margin: 0;
  margin-bottom: 1.3rem; }
  .universalh3 span {
    color: #DA212E; }

.universalParagraph {
  color: #9F9F9F;
  font-size: 0.9rem;
  line-height: 1.2rem;
  margin-bottom: 1.2rem; }

.multipleTitle {
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative; }
  .multipleTitle h1 {
    font-size: 6rem;
    font-weight: 800;
    color: #F6F6F6;
    margin: 0;
    z-index: 1;
    position: relative; }
    .multipleTitle h1:before {
      content: '';
      width: 2px;
      height: 2rem;
      background: #DA212E;
      position: absolute;
      top: -2rem;
      left: 0;
      right: 0;
      margin-right: auto;
      margin-left: auto; }
  .multipleTitle .box {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2; }
    .multipleTitle .box .universalh3 {
      margin-bottom: 0;
      background: white; }

.bredkramb {
  margin-bottom: 4.5rem; }
  .bredkramb h6 {
    font-size: 2.3rem;
    line-height: 2.2rem;
    font-weight: 800;
    color: #222222;
    margin-bottom: 5px; }
  .bredkramb .links {
    display: flex; }
    .bredkramb .links .nav-item {
      list-style: none;
      position: relative; }
      .bredkramb .links .nav-item a {
        color: #9F9F9F;
        font-size: 1rem;
        line-height: 1.2rem;
        text-decoration: none; }
        .bredkramb .links .nav-item a:after {
          content: '/';
          margin-left: 0.5rem;
          margin-right: 0.5rem; }
      .bredkramb .links .nav-item:last-child a {
        color: #DA212E; }
        .bredkramb .links .nav-item:last-child a::after {
          display: none; }

@media screen and (max-width: 767px) and (min-width: 0px) {
  .bredkramb {
    margin-bottom: 2rem; }
    .bredkramb h6 {
      margin-bottom: 1rem;
      padding-right: 1rem; }
    .bredkramb .links .nav-item a {
      font-size: 0.9rem; } }

.googleMapWrapper2 {
  width: 100%;
  height: 39rem;
  position: relative;
  z-index: 0;
  margin-top: -5rem; }
  .googleMapWrapper2 .googleMap {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: calc(50% + 270px);
    border-top-left-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important; }

@media screen and (max-width: 1200px) and (min-width: 992px) {
  .googleMapWrapper2 .googleMap {
    width: calc(50% + 225px); } }

@media screen and (max-width: 767px) and (min-width: 0px) {
  .googleMapWrapper2 {
    margin-left: 15px;
    margin-right: 15px;
    height: 20rem; }
    .googleMapWrapper2 .googleMap {
      position: relative;
      width: calc(100% - 30px);
      margin-top: 2rem !important; } }

@media screen and (max-width: 991px) and (min-width: 768px) {
  .googleMapWrapper2 {
    margin-left: 40px;
    margin-right: 40px;
    height: 20rem; }
    .googleMapWrapper2 .googleMap {
      position: relative;
      width: calc(100% - 80px);
      margin-top: 2rem !important; } }

.pagination {
  margin-top: 2.5rem; }
  .pagination li {
    background: #DCDCDC;
    margin-right: 10px;
    z-index: 4;
    height: 40px;
    width: 40px;
    text-align: center;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center; }
    .pagination li a {
      color: #222222;
      font-size: 0.8rem;
      font-weight: 700;
      cursor: pointer;
      text-decoration: none;
      border: 0;
      outline: none; }
  .pagination .active {
    background: #DA212E; }
    .pagination .active a {
      color: white; }
  .pagination .previous::after {
    content: '';
    border: solid #1F1A16;
    border-width: 0 0.1rem 0.1rem 0;
    display: inline-block;
    padding: 0.2rem;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    position: relative; }
  .pagination .next::after {
    content: '';
    border: solid #1F1A16;
    border-width: 0 0.1rem 0.1rem 0;
    display: inline-block;
    padding: 0.2rem;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    position: relative; }

.required {
  border: 1px solid #DA212E !important; }

.form-wrapper {
  width: 100%; }
  .form-wrapper P {
    margin-bottom: 0px !important; }
    .form-wrapper P span {
      color: #DA212E;
      margin-left: 5px; }
  .form-wrapper .sub {
    font-size: 12px; }
  .form-wrapper .check-wrap {
    margin-top: 35px; }

.check-input-wrap {
  display: flex; }

.input-wrap {
  margin-bottom: 20px;
  margin-top: 10px; }
  @media only screen and (max-width: 1000px) {
    .input-wrap {
      margin-bottom: 5px;
      margin-top: 10px; } }
  .input-wrap input, .input-wrap textarea {
    border: 1px solid #ced4da;
    padding: 8px 15px;
    width: 100%; }
  .input-wrap textarea {
    border-radius: 10px; }
  .input-wrap .react-datepicker-wrapper {
    width: 100%; }
  .input-wrap .checkbox {
    width: 32px;
    height: 32px;
    border: 3px solid #ced4da;
    margin-left: 10px; }
  .input-wrap .checked {
    display: flex;
    align-items: center;
    justify-content: center; }
    .input-wrap .checked::after {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      background-color: #DA212E; }
  .input-wrap .select-field {
    border: 1px solid #ced4da;
    width: 100%;
    border-radius: 1.25rem;
    height: 2.5rem;
    color: #1F1A16;
    margin-bottom: 2rem; }
    .input-wrap .select-field a {
      color: #1F1A16; }
    .input-wrap .select-field .dropdown-toggle::after {
      float: right;
      margin-top: 14px; }
    .input-wrap .select-field .dropdown-menu {
      width: 100%; }

.check-align .check-input-wrap {
  justify-content: space-between; }

.price-modal {
  text-align: right;
  position: fixed;
  background: #F8F8F8;
  border-radius: 10px;
  padding: 1rem;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
  z-index: 99999; }
  @media only screen and (max-width: 1000px) {
    .price-modal {
      bottom: 0;
      width: 100%;
      left: 0; } }
  .price-modal h6 {
    color: #DA212E !important;
    font-size: 18px !important; }
  .price-modal h3 {
    text-align: right; }
  .price-modal p {
    font-size: 14px !important; }

.input-wrap .dropdown-menu {
  max-height: 300px;
  overflow-y: auto; }

.title-big {
  font-weight: 700 !important;
  padding-bottom: 20px !important;
  padding-top: 40px !important; }

.choose-file {
  position: relative;
  height: 100px;
  border: 1px solid #ced4da;
  border-radius: 10px;
  margin-bottom: 20px;
  margin-top: 10px; }
  .choose-file input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    height: 100px !important;
    width: 100%;
    cursor: pointer; }
  .choose-file .wrap-file {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    pointer-events: none; }

.red-circle {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: #DA212E;
  margin: 0 auto; }

.green-circle {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: #00d20e;
  margin: 0 auto; }

.header-top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1.25rem;
  padding-bottom: 12px;
  position: relative; }
  @media only screen and (max-width: 1000px) {
    .header-top {
      display: none; } }
  .header-top .spacer {
    height: 1px;
    background-color: #fff;
    position: absolute;
    bottom: 0;
    left: 1.25rem;
    right: 0; }
  .header-top .links {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #fff;
    font-weight: 300; }
    .header-top .links a {
      color: white;
      margin-right: 39px; }
  .header-top .links-icon {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #fff;
    font-weight: 500; }
    .header-top .links-icon a {
      color: #fff;
      display: flex;
      align-items: center;
      margin-left: 33px; }
      .header-top .links-icon a button {
        background: white;
        border: 0;
        border-radius: 100%;
        height: 32px;
        width: 32px;
        outline: none;
        margin-right: 9px;
        padding: 0; }
        .header-top .links-icon a button svg {
          height: 1.2rem;
          width: 1.2rem;
          fill: #DA212E;
          display: block;
          margin: auto; }
        .header-top .links-icon a button:hover {
          background: #DA212E; }
          .header-top .links-icon a button:hover svg {
            fill: #FFFFFF; }
      .header-top .links-icon a:last-child button {
        margin-bottom: 0; }

.headerIconsWrapper div {
  margin-block-end: 100px; }
  .headerIconsWrapper div a {
    display: none !important; }
    @media only screen and (max-width: 1000px) {
      .headerIconsWrapper div a {
        display: flex !important; } }
  @media only screen and (max-width: 1000px) {
    .headerIconsWrapper div {
      margin-bottom: 0; } }

.redh1 {
  color: #DA212E; }

.check-input-wrap1 .checkbox {
  margin-left: 0;
  margin-right: 15px; }

.hint {
  margin-top: -10px;
  padding-bottom: 30px !important;
  font-size: 14px !important; }
  @media only screen and (max-width: 768px) {
    .hint {
      margin-top: 15px; } }
  .hint a {
    color: #DA212E !important;
    cursor: pointer; }

.text-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center; }
  .text-modal > div {
    background-color: #fff;
    width: 500px;
    margin: 0 auto;
    border-radius: 6px;
    padding: 20px; }
    @media only screen and (max-width: 768px) {
      .text-modal > div {
        width: 95%; } }
    .text-modal > div h3 {
      font-size: 18px;
      border-bottom: 1px solid #9F9F9F;
      margin-bottom: 20px;
      padding-bottom: 5px; }
    .text-modal > div p {
      font-size: 16px; }
    .text-modal > div .button-wrap {
      float: right;
      margin-top: 20px; }

.info-phone-text {
  color: #DA212E;
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 0;
  line-height: 2.3rem; }
  @media only screen and (max-width: 768px) {
    .info-phone-text {
      display: none; } }

.info-phone-text1 {
  color: #DA212E;
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 0;
  line-height: 2.3rem;
  display: none; }
  @media only screen and (max-width: 768px) {
    .info-phone-text1 {
      display: block; } }

.link-no-decoration {
  text-decoration: none !important; }
  .link-no-decoration * {
    text-decoration: none !important; }

.aboutSection {
  padding-top: 6rem; }
  .aboutSection .universalSilverh1 {
    margin-top: -1.5rem; }

@media screen and (max-width: 767px) and (min-width: 300px) {
  .aboutSection {
    padding-top: 4rem; }
    .aboutSection .universalSilverh1 {
      margin-top: 1rem; }
    .aboutSection .animateButton {
      margin-top: 1.8rem; }
    .aboutSection p:nth-child(2) {
      display: none; } }

.animateButton {
  background: #DA212E;
  border-radius: 1.25rem;
  border: 0 !important;
  outline: none !important;
  overflow: hidden;
  position: relative;
  padding: 0.8rem 2rem;
  line-height: 0.8rem;
  -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1); }
  .animateButton span {
    font-size: 0.8rem;
    font-weight: 600;
    line-height: 0.8rem;
    position: relative;
    color: white;
    z-index: 2;
    letter-spacing: 1px; }
  .animateButton:focus, .animateButton:hover {
    outline: none !important;
    box-shadow: none !important;
    border: 0 !important; }

.animateButtonBordered {
  background: #FFFFFF;
  border-radius: 1.25rem;
  border: 3px solid #DA212E !important;
  outline: none !important;
  overflow: hidden;
  position: relative;
  padding: 0.65rem 1.85rem;
  line-height: 0.8rem; }
  .animateButtonBordered span {
    font-size: 0.8rem;
    font-weight: 700;
    line-height: 0.8rem;
    position: relative;
    color: #222222;
    z-index: 2;
    letter-spacing: 1px; }
  .animateButtonBordered:focus, .animateButtonBordered:hover {
    outline: none !important;
    box-shadow: none !important;
    border: 3px solid #155B98 !important;
    background: transparent; }
    .animateButtonBordered:focus span, .animateButtonBordered:hover span {
      color: white; }

.animacija {
  opacity: 1;
  border-radius: 1.25rem;
  background: #155B98;
  animation-name: buttonAnimacija;
  animation-duration: 0.7s;
  width: 800px;
  height: 800px;
  margin-left: -420px;
  margin-top: -450px;
  z-index: 4; }

@keyframes buttonAnimacija {
  from {
    width: 0px;
    height: 0px;
    border-radius: 100%;
    margin-left: 0;
    margin-top: 0; }
  to {
    width: 800px;
    height: 800px;
    margin-left: -420px;
    margin-top: -450px;
    border-radius: 100%; } }

.Navigacija {
  padding-top: 1rem;
  position: relative; }
  .Navigacija .fa {
    color: white; }
  .Navigacija .headerLogo svg {
    width: 100%;
    max-width: 235px; }
  .Navigacija .btn-menu {
    display: none; }
  .Navigacija .navbar {
    justify-content: space-between;
    padding-top: 1rem;
    padding-right: 0;
    padding-left: 1.25rem; }
    .Navigacija .navbar .nav-item {
      list-style: none; }
      .Navigacija .navbar .nav-item .nav-link {
        padding: 0;
        font-size: 0.7rem;
        line-height: 0.85rem;
        font-weight: 600;
        color: #FFFFFF;
        position: relative;
        letter-spacing: 0.5px; }
    .Navigacija .navbar .navbar-collapse {
      justify-content: space-between; }
    .Navigacija .navbar svg {
      fill: #FFFFFF;
      margin-left: 0.4rem;
      margin-bottom: 0.2rem;
      width: 0.55rem;
      height: 0.45rem; }
  .Navigacija .active:after {
    content: '';
    position: absolute;
    width: 0.4rem;
    height: 0.4rem;
    border-radius: 100%;
    background: #FFFFFF;
    bottom: -1rem;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto; }
  .Navigacija .dropdown-menu {
    margin-top: 1.1rem;
    -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    border: 0;
    padding: 1rem 1rem; }
    .Navigacija .dropdown-menu .nav-item {
      margin-bottom: 1rem; }
      .Navigacija .dropdown-menu .nav-item .nav-link {
        color: #222222 !important;
        font-size: 0.8rem;
        font-weight: 600;
        line-height: 1.2rem;
        display: flex;
        justify-content: space-between;
        letter-spacing: 0 !important; }
        .Navigacija .dropdown-menu .nav-item .nav-link svg {
          fill: #222222;
          transform: rotate(-90deg); }
      .Navigacija .dropdown-menu .nav-item:last-child {
        margin-bottom: 0; }
        .Navigacija .dropdown-menu .nav-item:last-child:after {
          display: none; }
      .Navigacija .dropdown-menu .nav-item:after {
        content: '';
        height: 1px;
        background: #E9E9E9;
        position: absolute;
        left: 0;
        width: 100%;
        bottom: -0.5rem; }
      .Navigacija .dropdown-menu .nav-item .active {
        color: #DA212E !important; }
    .Navigacija .dropdown-menu .dropdown {
      position: relative; }
      .Navigacija .dropdown-menu .dropdown .dropdown-menu {
        top: -1rem;
        left: calc(100% + 1.5rem);
        margin-top: 0; }
        .Navigacija .dropdown-menu .dropdown .dropdown-menu::before {
          height: 1rem;
          width: 1rem;
          background: white;
          z-index: -1;
          transform: rotate(45deg);
          content: ' ';
          position: absolute;
          border-radius: 4px;
          left: -5px;
          top: 20px; }
    .Navigacija .dropdown-menu::before {
      height: 1rem;
      width: 1rem;
      background: white;
      z-index: -1;
      transform: rotate(45deg);
      content: ' ';
      position: absolute;
      top: -0.4rem;
      border-radius: 4px;
      left: 1.2rem; }
    .Navigacija .dropdown-menu hr {
      width: 93%;
      margin: 0;
      margin: auto; }
    .Navigacija .dropdown-menu .dropdown-item {
      font-size: 0.8rem !important;
      line-height: 1.2rem !important;
      font-weight: 600 !important;
      margin-bottom: 1rem;
      padding: 0;
      position: relative;
      color: #222222 !important;
      letter-spacing: 0 !important; }
      .Navigacija .dropdown-menu .dropdown-item:hover {
        color: #DA212E !important;
        background: transparent; }
      .Navigacija .dropdown-menu .dropdown-item:last-child {
        margin-bottom: 0; }
        .Navigacija .dropdown-menu .dropdown-item:last-child::after {
          display: none; }
      .Navigacija .dropdown-menu .dropdown-item:after {
        content: '';
        height: 1px;
        width: 100%;
        background: #E9E9E9;
        position: absolute;
        bottom: -0.4rem;
        right: 0; }
    .Navigacija .dropdown-menu .active {
      color: #DA212E !important;
      background: transparent;
      font-size: 0.8rem !important; }
  .Navigacija .dropdown-toggle::after {
    display: none; }
  .Navigacija .active::before {
    content: '' !important;
    position: absolute !important;
    width: 0.4rem !important;
    height: 0.4rem !important;
    border-radius: 100% !important;
    background: #FFFFFF !important;
    bottom: -1rem !important;
    left: 0 !important;
    right: 0 !important;
    margin-left: auto !important;
    margin-right: auto !important; }

.headerBG {
  position: absolute;
  top: 0;
  right: 0;
  width: calc(50% + 290px);
  object-fit: cover; }

.headerBG2 {
  position: absolute;
  top: 0;
  right: 0;
  width: calc(50% + 420px);
  object-fit: cover;
  right: -4.8rem; }

.headerIconsWrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%; }
  .headerIconsWrapper > div {
    z-index: 2;
    margin-top: -1.5rem; }
  .headerIconsWrapper a {
    display: block; }
    .headerIconsWrapper a button {
      background: white;
      border: 0;
      border-radius: 100%;
      height: 2.2rem;
      width: 2.2rem;
      outline: none;
      margin-bottom: 0.8rem;
      padding: 0; }
      .headerIconsWrapper a button svg {
        height: 1.2rem;
        width: 1.2rem;
        fill: #DA212E;
        display: block;
        margin: auto; }
      .headerIconsWrapper a button:hover {
        background: #DA212E; }
        .headerIconsWrapper a button:hover svg {
          fill: #FFFFFF; }
    .headerIconsWrapper a:last-child button {
      margin-bottom: 0; }

@media screen and (max-width: 991px) and (min-width: 300px) {
  .Navigacija {
    padding-top: 0rem; }
    .Navigacija .btn-menu {
      display: block;
      float: right;
      background: none;
      outline: none;
      border: none;
      transform: scale(2);
      padding-top: 1.6rem; }
    .Navigacija .navbar-collapse {
      position: fixed;
      left: 0;
      top: 100px;
      text-align: center;
      width: 100%; }
      .Navigacija .navbar-collapse .nav-item {
        list-style: none; }
    .Navigacija .active {
      color: #DA212E; }
      .Navigacija .active:after {
        display: none; } }

@media screen and (max-width: 1199px) and (min-width: 992px) {
  .headerBG {
    width: calc(50% + 240px); }
  .headerBG2 {
    width: calc(57% + 240px); } }

@media screen and (max-width: 991px) and (min-width: 768px) {
  .headerBG {
    width: calc(50% + 180px); }
  .headerBG2 {
    width: calc(50% + 240px); }
  .headerIconsWrapper > div {
    display: flex; }
    .headerIconsWrapper > div button {
      margin-left: 1rem; } }

@media screen and (max-width: 767px) and (min-width: 300px) {
  .headerBG {
    width: 195px;
    right: -10px; }
  .headerIconsWrapper {
    padding: 0 10px; }
    .headerIconsWrapper > div {
      margin-top: -1rem;
      z-index: 0; } }

.carousel {
  margin-top: 2.5rem;
  height: 640px; }
  .carousel .carousel-inner {
    overflow: visible !important; }
  .carousel .carouselText h1 {
    font-size: 2.3rem;
    font-weight: 800;
    margin: 0;
    line-height: 2.4rem;
    color: #222222; }
  .carousel .carouselText .redTitle {
    color: #DA212E; }
  .carousel .carouselText p {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.2rem;
    color: #222222;
    margin-top: 1.3rem;
    margin-bottom: 3.4rem;
    padding-right: 3rem; }
  .carousel .carouselText .bigTitle {
    font-size: 4.5rem;
    color: #F6F6F6;
    line-height: 4.5rem; }
  .carousel .scrollDiv {
    position: absolute;
    bottom: -10px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto; }
    .carousel .scrollDiv button {
      background: transparent;
      border: 0;
      outline: none;
      display: block;
      margin: auto; }
    .carousel .scrollDiv p {
      display: table-caption;
      font-size: 0.5rem;
      font-weight: 700;
      line-height: 0.6rem;
      color: #222222;
      margin-top: 0.4rem; }
  .carousel .carousel-indicators {
    justify-content: unset !important;
    margin: unset !important; }
    .carousel .carousel-indicators li {
      height: 0.5rem;
      width: 0.5rem;
      border-radius: 100%;
      background-color: #BCBCBC; }
    .carousel .carousel-indicators .active {
      background-color: #DA212E; }

@media screen and (max-width: 1200px) and (min-width: 992px) {
  .carousel {
    height: 500px; } }

@media screen and (max-width: 991px) and (min-width: 768px) {
  .carousel {
    height: 440px; } }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .carousel {
    margin-top: 0;
    height: 270px; }
    .carousel .carouselText .bigTitle {
      display: none; }
    .carousel .carouselText p {
      font-size: 0.8rem;
      padding-right: 1.5rem;
      margin-bottom: 1.5rem; }
    .carousel .carouselText h1 {
      font-size: 1.2rem;
      line-height: 1.4rem; }
    .carousel .carousel-item {
      position: unset !important; }
    .carousel .carousel-indicators {
      right: 0;
      left: unset;
      z-index: 1; }
    .carousel .carousel-inner {
      position: unset !important; }
  .positionFix {
    position: unset !important; } }

.servicesSection {
  padding-top: 7rem;
  padding-bottom: 5rem; }
  .servicesSection:hover .servicesBoxWrapper:after {
    display: none; }
  .servicesSection .servicesBoxWrapper {
    position: relative;
    padding-top: 5px;
    padding-bottom: 5px; }
    .servicesSection .servicesBoxWrapper:hover {
      transform: scale(1.1);
      transition: all 0.4s ease;
      -webkit-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
      -moz-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15); }
      .servicesSection .servicesBoxWrapper:hover .servicesBox {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        padding: 0 1.5rem; }
    .servicesSection .servicesBoxWrapper:after {
      position: absolute;
      content: '';
      width: 2px;
      height: 7.55rem;
      top: 0;
      right: 0;
      bottom: 0;
      margin-bottom: auto;
      margin-top: auto;
      background: #E8E8E8; }
    .servicesSection .servicesBoxWrapper:last-child::after {
      display: none; }
  .servicesSection .servicesBox {
    padding: 0 1rem; }
    .servicesSection .servicesBox svg {
      display: block;
      margin: auto;
      width: 2.6rem;
      height: 2.6rem; }
    .servicesSection .servicesBox a {
      text-decoration: none; }
    .servicesSection .servicesBox h3 {
      font-size: 1.1rem;
      font-weight: 600;
      line-height: 1.2rem;
      padding-top: 1.3rem;
      margin-bottom: 0.8rem;
      text-align: center;
      color: #222222; }
    .servicesSection .servicesBox p {
      text-align: center;
      font-size: 0.8rem; }
    .servicesSection .servicesBox button {
      background: #E8E8E8;
      border-radius: 100%;
      border: 0;
      outline: none;
      height: 1.6rem;
      width: 1.6rem;
      display: block;
      margin: auto;
      padding: 0; }
      .servicesSection .servicesBox button:hover {
        background: #DA212E; }
      .servicesSection .servicesBox button svg {
        fill: #FFFFFF;
        height: 12px;
        width: 12px; }
  .servicesSection .serviceBoxRow {
    padding-top: 3rem; }

@media screen and (max-width: 991px) and (min-width: 300px) {
  .servicesBoxWrapper::after {
    display: none; } }

@media screen and (max-width: 991px) and (min-width: 768px) {
  .servicesBoxWrapper {
    margin-bottom: 3rem; } }

@media screen and (max-width: 767px) and (min-width: 300px) {
  .servicesSection {
    padding-bottom: 2rem; }
    .servicesSection .servicesBoxWrapper {
      margin-bottom: 2rem; }
      .servicesSection .servicesBoxWrapper:hover .servicesBox {
        position: unset; }
    .servicesSection .servicesBox {
      margin-top: 3rem;
      height: 200px;
      display: contents;
      vertical-align: middle; }
    .servicesSection .serviceBoxRow {
      padding-top: 2rem; } }

.shippingSafetySection .universalh3 {
  margin-bottom: 2.5rem; }

.shippingSafetySection .animateButtonBordered {
  margin-top: 1.3rem; }

@media screen and (min-width: 1200px) {
  .shippingSafetySection .universalParagraph {
    margin-left: 1rem; }
  .shippingSafetySection .animateButtonBordered {
    margin-left: 1rem; } }

@media screen and (max-width: 767px) and (min-width: 300px) {
  .shippingSafetySection p:nth-child(2) {
    display: none !important; } }

.newsSection {
  padding-top: 6rem;
  margin-bottom: 5rem; }
  .newsSection a {
    text-decoration: none !important; }
  .newsSection .buttonWrapper {
    width: 100%;
    margin-top: 2.5rem; }
    .newsSection .buttonWrapper .animateButtonBordered {
      display: block;
      margin: auto; }
  .newsSection .multipleTitle {
    margin-bottom: 2rem; }
  .newsSection .newsBoxWrapper:hover .newsBox {
    transition: 0.7s ease;
    -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.3);
    transform: scale(1.1); }
  .newsSection .newsBoxWrapper .newsBox {
    -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    overflow: hidden; }
    .newsSection .newsBoxWrapper .newsBox img {
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      width: 100%; }
    .newsSection .newsBoxWrapper .newsBox .textBox {
      height: 8rem;
      padding: 1rem 0.5rem;
      text-align: center; }
      .newsSection .newsBoxWrapper .newsBox .textBox h6 {
        font-size: 1rem;
        line-height: 1.3rem;
        color: #222222;
        font-weight: 600;
        margin-bottom: 0.7rem; }
      .newsSection .newsBoxWrapper .newsBox .textBox p {
        color: #9F9F9F;
        font-size: 0.8rem;
        line-height: 1.2rem;
        margin: 0; }

.newsBoxWrapper2 {
  margin-bottom: 1.5rem; }
  .newsBoxWrapper2 a {
    text-decoration: none !important; }
  .newsBoxWrapper2:hover .newsBox {
    transition: 0.7s ease;
    -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.3);
    transform: scale(1.1); }
  .newsBoxWrapper2 .newsBox {
    -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    overflow: hidden; }
    .newsBoxWrapper2 .newsBox img {
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      width: 100%;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      margin-bottom: 0; }
    .newsBoxWrapper2 .newsBox .textBox {
      height: 9rem;
      padding: 1rem 0.2rem;
      text-align: center; }
      .newsBoxWrapper2 .newsBox .textBox h6 {
        font-size: 1rem;
        line-height: 1.3rem;
        color: #222222;
        font-weight: 600;
        margin-bottom: 0.7rem;
        margin-top: 0; }
      .newsBoxWrapper2 .newsBox .textBox p {
        color: #9F9F9F;
        font-size: 0.8rem;
        line-height: 1.2rem;
        margin: 0; }

.newsBoxWrapper3 .newsBox .textBox {
  height: 8rem; }

@media screen and (max-width: 991px) and (min-width: 300px) {
  .newsSection .newsBoxWrapper {
    margin-bottom: 3rem; }
  .newsSection .newsBoxWrapper2 {
    margin-bottom: 3rem; } }

@media screen and (max-width: 767px) and (min-width: 300px) {
  .newsSection {
    margin-top: 0rem;
    margin-bottom: 1rem; }
    .newsSection .newsBoxWrapper:hover .newsBox {
      transform: scale(1.05); }
    .newsSection .newsBoxWrapper .newsBox .textBox {
      height: 9rem; }
    .newsSection .newsBoxWrapper2:hover .newsBox {
      transform: scale(1.05); }
    .newsSection .newsBoxWrapper2 .newsBox .textBox {
      height: 9rem; }
    .newsSection .buttonWrapper {
      margin-top: 0; } }

.mapWrapper {
  position: relative;
  height: 31rem;
  overflow: hidden;
  margin-bottom: 30px; }
  .mapWrapper .googleMap {
    width: 54%;
    position: absolute !important;
    height: 30rem;
    top: 0;
    right: 0;
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
    -webkit-box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.2); }
    .mapWrapper .googleMap .googleModal {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      bottom: 0;
      margin-top: -54px; }
    .mapWrapper .googleMap .googleBox {
      -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
      border-radius: 16px;
      overflow: hidden;
      display: block;
      margin: auto;
      position: relative;
      width: 12rem; }
      .mapWrapper .googleMap .googleBox button {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        background: transparent;
        border: 0;
        outline: 0;
        color: #DA212E;
        transform: scale(1.2); }
      .mapWrapper .googleMap .googleBox img {
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        width: 100%; }
      .mapWrapper .googleMap .googleBox .textBox {
        text-align: left;
        background: white;
        padding: 1.5rem 1rem; }
        .mapWrapper .googleMap .googleBox .textBox h6 {
          font-size: 1rem;
          line-height: 1.3rem;
          color: #222222;
          font-weight: 600;
          margin-bottom: 0.7rem; }
        .mapWrapper .googleMap .googleBox .textBox p {
          color: #222222;
          font-size: 0.8rem;
          line-height: 1.2rem;
          margin: 0; }
          .mapWrapper .googleMap .googleBox .textBox p span {
            font-weight: 600;
            color: #222222; }
  .mapWrapper .blueBox {
    margin-top: 3rem;
    background: #155B98;
    border-radius: 1rem;
    -webkit-box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.2);
    padding-top: 2.5rem;
    padding-right: 1.5rem;
    padding-bottom: 0.8rem;
    margin-bottom: 3.5rem; }
    .mapWrapper .blueBox h6 {
      color: white;
      font-size: 1.6rem;
      text-align: right;
      line-height: 1.6rem; }
  .mapWrapper .parahraphWrapper {
    padding: 0 3rem; }
  .mapWrapper .universalParagraph {
    font-size: 0.9rem; }
    .mapWrapper .universalParagraph span {
      font-weight: 600; }
  .mapWrapper .animateButtonBordered {
    border: 3px solid #155B98 !important;
    margin-top: 1.2rem; }

.googleMapWrapper2 .googleModal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.googleMapWrapper2 .googleBox {
  -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  overflow: hidden;
  display: block;
  margin: auto;
  position: relative;
  width: 8rem; }
  .googleMapWrapper2 .googleBox button {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    background: transparent;
    border: 0;
    outline: 0;
    color: #DA212E;
    transform: scale(1.2); }
  .googleMapWrapper2 .googleBox img {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    width: 100%; }
  .googleMapWrapper2 .googleBox .textBox {
    text-align: left;
    background: white;
    padding: 0.5rem 1rem; }
    .googleMapWrapper2 .googleBox .textBox h6 {
      font-size: 1rem;
      line-height: 1.3rem;
      color: #222222;
      font-weight: 600;
      margin-bottom: 0.7rem; }
    .googleMapWrapper2 .googleBox .textBox p {
      color: #222222;
      font-size: 0.8rem;
      line-height: 1.2rem;
      margin: 0; }
      .googleMapWrapper2 .googleBox .textBox p span {
        font-weight: 600;
        color: #222222; }

@media screen and (max-width: 767px) and (min-width: 300px) {
  .mapWrapper {
    height: unset;
    margin-top: unset; }
    .mapWrapper .googleMap {
      position: relative !important;
      width: 100%;
      margin: 3rem 15px; }
    .mapWrapper .parahraphWrapper {
      padding: 0;
      text-align: center; }
    .mapWrapper .blueBox {
      margin: 3rem 0;
      padding: 2rem 1rem; }
      .mapWrapper .blueBox h6 {
        font-size: 1.4rem;
        text-align: center; }
  .googleMapWrapper2 {
    margin-top: 0; } }

.educationSection {
  margin-top: 7.5rem;
  clear: both; }
  .educationSection .educationBox {
    border-radius: 1rem;
    -webkit-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    padding: 1rem 0.5rem;
    margin-top: 2.5rem;
    display: flex; }
    .educationSection .educationBox img {
      float: right;
      position: relative;
      right: -4rem; }
  .educationSection .universalParagraph {
    color: #222222;
    margin-bottom: 1rem; }
  .educationSection a {
    color: #222222;
    font-weight: 700;
    line-height: 0.9rem;
    letter-spacing: 1px;
    font-size: 0.8rem;
    text-decoration: none !important; }
    .educationSection a:hover {
      text-decoration: underline !important;
      color: #222222; }
    .educationSection a svg {
      fill: #DA212E;
      width: 0.65rem;
      height: 0.55rem;
      margin-top: -0.2rem; }

@media screen and (max-width: 1200px) and (min-width: 768px) {
  .educationSection .educationBox img {
    right: -3rem; } }

@media screen and (max-width: 767px) and (min-width: 300px) {
  .educationSection .educationBox {
    display: block; }
    .educationSection .educationBox img {
      margin-bottom: -5rem !important;
      position: unset;
      display: block;
      float: unset;
      margin: auto;
      right: unset;
      max-width: 100px;
      margin-top: 2rem !important; } }

.footer {
  margin-top: 6rem;
  position: relative;
  overflow: hidden; }
  .footer .footerNavbar {
    display: flex;
    width: 100%;
    padding-left: 1.25rem;
    justify-content: space-between; }
    .footer .footerNavbar .nav-item {
      list-style: none; }
      .footer .footerNavbar .nav-item a {
        font-size: 0.7rem;
        line-height: 0.75rem;
        letter-spacing: 0.5px;
        color: #155B98;
        font-weight: 600;
        text-decoration: none; }
        .footer .footerNavbar .nav-item a svg {
          fill: #155B98;
          width: 0.55rem;
          height: 0.45rem;
          margin-top: -2px; }
  .footer .suggestions {
    text-align: right;
    margin-top: 5.3rem; }
    .footer .suggestions h6 {
      color: #222222;
      font-weight: 700;
      line-height: 2.3rem;
      font-size: 1.1rem;
      margin-bottom: 0.6rem; }
    .footer .suggestions p {
      margin-bottom: 0;
      margin-left: -1rem; }
      .footer .suggestions p span {
        color: #DA212E; }
  .footer .footerLogo svg {
    width: calc(100% + 1.5rem); }
  .footer .footerBG {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    z-index: -1;
    width: 55%;
    height: 100%; }
  .footer .socijalneMreze .social {
    color: #FFFFFF;
    font-size: 0.7rem;
    line-height: 0.75rem;
    letter-spacing: 0.5px;
    font-weight: 600;
    margin-top: 2rem;
    margin-bottom: 1rem; }
  .footer .socijalneMreze svg {
    margin-right: 0.7rem; }
  .footer .copyright {
    justify-content: space-between;
    margin-top: 3rem;
    padding-bottom: 2rem; }
    .footer .copyright p {
      color: #FFFFFF;
      font-size: 0.7rem;
      line-height: 0.75rem;
      letter-spacing: 0.5px;
      font-weight: 400;
      margin-bottom: 0.6rem; }
      .footer .copyright p a {
        font-weight: 600;
        color: #FFFFFF; }
      .footer .copyright p:last-child {
        margin-bottom: 0; }
    .footer .copyright .info {
      margin-top: -3.5rem; }
      .footer .copyright .info h6 {
        color: #DA212E;
        font-size: 1.2rem;
        font-weight: 700;
        margin-bottom: 0;
        line-height: 2.3rem; }
      .footer .copyright .info h1 {
        font-size: 4.5rem;
        line-height: 2.3rem;
        font-weight: 700;
        color: #DA212E;
        margin-bottom: 0; }

@media screen and (max-width: 1680px) and (min-width: 1600px) {
  .footer .footerBG {
    left: -3%; } }

@media screen and (max-width: 1599px) and (min-width: 1400px) {
  .footer .footerBG {
    left: -9%;
    width: 60%; } }

@media screen and (max-width: 1399px) and (min-width: 1300px) {
  .footer .footerBG {
    left: -10%;
    width: 60%; } }

@media screen and (max-width: 1299px) and (min-width: 1025px) {
  .footer .footerBG {
    left: -12%;
    width: 60%; } }

@media screen and (max-width: 1024px) and (min-width: 992px) {
  .footer .footerBG {
    left: -15%;
    width: 62%; } }

@media screen and (max-width: 991px) and (min-width: 768px) {
  .footer .footerBG {
    top: 2rem;
    left: -22%;
    width: 85%; } }

@media screen and (max-width: 767px) and (min-width: 300px) {
  .footer {
    background: #155B98;
    margin-top: 6rem; }
    .footer .footerLogo svg {
      margin-bottom: 1rem; }
    .footer .suggestions {
      margin-top: 2rem; }
      .footer .suggestions h6,
      .footer .suggestions p {
        color: #FFFFFF;
        text-align: left;
        margin-left: 0; }
      .footer .suggestions p {
        font-size: 1rem;
        line-height: 1.4em; }
        .footer .suggestions p span {
          font-weight: 700;
          color: white;
          position: relative; }
          .footer .suggestions p span:after {
            content: '';
            bottom: 0;
            left: 0;
            position: absolute;
            height: 1px;
            width: 100%;
            background: white; }
    .footer .socijalneMreze {
      margin-top: 2rem; }
      .footer .socijalneMreze .social {
        font-size: 1rem !important;
        font-weight: 400 !important;
        margin-bottom: 2rem !important; }
    .footer .footerNavbar {
      display: none; }
    .footer .copyright .info {
      margin-top: -2rem;
      float: right; }
    .footer .copyright p {
      font-size: 0.8rem;
      line-height: 1rem; }
    .footer .footerBG {
      display: none; } }

.desktopSidemenu {
  background: #F8F8F8;
  border-radius: 10px;
  padding: 1rem;
  -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1); }
  .desktopSidemenu .nav-item {
    list-style: none;
    margin-bottom: 1.8rem;
    position: relative;
    z-index: 2; }
    .desktopSidemenu .nav-item .nav-link {
      font-size: 0.9rem;
      font-weight: 600;
      line-height: 1.2rem;
      color: #222222;
      padding: 0; }
      .desktopSidemenu .nav-item .nav-link:after {
        content: '';
        position: absolute;
        bottom: -0.9rem;
        left: 0;
        height: 1px;
        width: 100%;
        background: #BCBCBC; }
    .desktopSidemenu .nav-item:last-child {
      margin-bottom: 0.5rem; }
      .desktopSidemenu .nav-item:last-child .nav-link::after {
        display: none; }
  .desktopSidemenu h6 {
    font-size: 1.6rem !important;
    font-weight: 700 !important;
    line-height: 2.3rem !important;
    color: #222222 !important;
    margin-bottom: 1rem !important;
    margin-top: 0 !important; }
  .desktopSidemenu .active {
    color: #DA212E !important; }

.contactDesktopSidemenu {
  margin-top: 40px; }
  .contactDesktopSidemenu h6 {
    font-size: 22px !important;
    margin-bottom: 8px !important; }
  .contactDesktopSidemenu p {
    font-size: 14px !important;
    margin-bottom: 8px !important; }

@media screen and (max-width: 991px) and (min-width: 0px) {
  .desktopSidemenu {
    display: none; } }

.navbarResposniveOpen2 {
  display: block !important;
  cursor: pointer;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  right: 0;
  top: 0;
  position: fixed;
  height: 100%;
  z-index: 16; }
  .navbarResposniveOpen2 ul {
    list-style: none; }
    .navbarResposniveOpen2 ul .nav-link {
      color: white;
      padding: 0px; }
  .navbarResposniveOpen2 .active {
    color: #DA212E !important; }
    .navbarResposniveOpen2 .active:before {
      all: unset !important; }

.sadrzaj2 {
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(130%, 0, 0);
  -moz-transform: translate3d(130%, 0, 0);
  transform: translate3d(130%, 0, 0);
  -webkit-transition: transform 1s cubic-bezier(0.25, 1, 0.2, 1);
  -moz-transition: transform 1s cubic-bezier(0.25, 1, 0.2, 1);
  transition: transform 1s cubic-bezier(0.25, 1, 0.2, 1);
  width: 100%;
  float: right;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2; }
  .sadrzaj2 .closeIcon {
    position: absolute;
    top: 2rem;
    right: 2rem;
    background: transparent !important;
    border: 0;
    outline: none !important;
    z-index: 5; }
  .sadrzaj2 .content {
    background: #155B98;
    height: 100vh;
    padding: 3rem;
    position: relative;
    padding-top: 10rem !important;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(130%, 0, 0);
    -moz-transform: translate3d(130%, 0, 0);
    transform: translate3d(130%, 0, 0);
    -webkit-transition: transform 1s cubic-bezier(0.25, 1, 0.2, 1);
    -moz-transition: transform 1s cubic-bezier(0.25, 1, 0.2, 1);
    transition: transform 1s cubic-bezier(0.25, 1, 0.2, 1); }
    .sadrzaj2 .content .nav-link {
      color: white;
      list-style-type: none;
      text-align: left;
      font-weight: 600;
      font-size: 1.4rem;
      margin-bottom: 1.3rem; }
    .sadrzaj2 .content .arrowRight {
      background: transparent !important;
      border: 0;
      outline: none;
      font-size: 1.4rem;
      color: white;
      font-weight: 600;
      padding: 0px;
      margin-bottom: 1.3rem;
      display: flex;
      justify-content: space-between;
      width: 100%; }
      .sadrzaj2 .content .arrowRight svg {
        margin-left: 1rem;
        height: 1.6rem; }
  .sadrzaj2 .noContent {
    background: #155B98;
    height: 100vh;
    padding: 3rem;
    position: relative;
    padding-top: 10rem !important;
    overflow: hidden; }
    .sadrzaj2 .noContent .nav-link {
      color: white;
      list-style-type: none;
      text-align: left;
      font-weight: 600;
      font-size: 1.4rem;
      margin-bottom: 1.3rem; }
    .sadrzaj2 .noContent .closeIcon {
      position: absolute;
      top: 2rem;
      right: 2rem;
      background: transparent !important;
      border: 0;
      outline: none !important;
      z-index: 5; }
    .sadrzaj2 .noContent .arrowRight {
      background: transparent !important;
      border: 0;
      outline: none;
      font-size: 1.4rem;
      color: white;
      font-weight: 600;
      padding: 0px;
      margin-bottom: 1.3rem; }
      .sadrzaj2 .noContent .arrowRight svg {
        margin-left: 1rem;
        height: 1.6rem; }

.sadrzajOpen2 {
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: transform 1s cubic-bezier(0.25, 1, 0.2, 1);
  -moz-transition: transform 1s cubic-bezier(0.25, 1, 0.2, 1);
  transition: transform 1s cubic-bezier(0.25, 1, 0.2, 1);
  width: 100%;
  float: right;
  position: relative;
  animation: slideInLeft;
  height: 100%;
  text-align: left; }
  .sadrzajOpen2 > div {
    background: #155B98;
    height: 100%; }
  .sadrzajOpen2 .closeIcon {
    position: absolute;
    top: 2rem;
    right: 2rem;
    background: transparent !important;
    border: 0;
    outline: none !important;
    z-index: 6; }
  .sadrzajOpen2 .content {
    background: #155B98;
    height: 100vh;
    position: relative;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transition: transform 1s cubic-bezier(0.25, 1, 0.2, 1);
    -moz-transition: transform 1s cubic-bezier(0.25, 1, 0.2, 1);
    transition: transform 1s cubic-bezier(0.25, 1, 0.2, 1);
    padding: 3rem;
    padding-top: 10rem !important; }
    .sadrzajOpen2 .content .nav-link {
      color: white;
      list-style-type: none;
      text-align: left;
      font-weight: 600;
      font-size: 1.4rem;
      margin-bottom: 1.3rem; }
    .sadrzajOpen2 .content .arrowRight {
      background: transparent !important;
      border: 0;
      outline: none;
      font-size: 1.4rem;
      color: white;
      font-weight: 600;
      padding: 0px;
      margin-bottom: 1.3rem;
      display: flex;
      justify-content: space-between;
      width: 100%; }
      .sadrzajOpen2 .content .arrowRight svg {
        margin-left: 1rem;
        height: 1.6rem; }
  .sadrzajOpen2 .noContent {
    background: #155B98;
    height: 100vh;
    padding: 3rem;
    position: relative;
    padding-top: 3rem !important;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(-130%, 0, 0);
    -moz-transform: translate3d(-130%, 0, 0);
    transform: translate3d(-130%, 0, 0);
    -webkit-transition: transform 1s cubic-bezier(0.25, 1, 0.2, 1);
    -moz-transition: transform 1s cubic-bezier(0.25, 1, 0.2, 1);
    transition: transform 1s cubic-bezier(0.25, 1, 0.2, 1); }
    .sadrzajOpen2 .noContent .nav-link {
      color: white;
      list-style-type: none;
      text-align: left;
      font-weight: 600;
      font-size: 1.4rem;
      margin-bottom: 1.3rem; }
    .sadrzajOpen2 .noContent .arrowRight {
      background: transparent !important;
      border: 0;
      outline: none;
      font-size: 1.4rem;
      color: white;
      font-weight: 600;
      padding: 0px;
      margin-bottom: 1.3rem; }
      .sadrzajOpen2 .noContent .arrowRight svg {
        margin-left: 1rem;
        height: 1.6rem; }
  .sadrzajOpen2 .content2 {
    background: #155B98;
    height: 100%;
    padding: 3rem !important;
    padding-top: 6rem !important;
    position: fixed;
    top: 0;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transition: transform 1s cubic-bezier(0.25, 1, 0.2, 1);
    -moz-transition: transform 1s cubic-bezier(0.25, 1, 0.2, 1);
    transition: transform 1s cubic-bezier(0.25, 1, 0.2, 1);
    width: 100%;
    overflow: auto;
    /* Track */ }
    .sadrzajOpen2 .content2::-webkit-scrollbar {
      width: 8px; }
    .sadrzajOpen2 .content2::-webkit-scrollbar-track {
      background: #949494;
      border-radius: 20px; }
    .sadrzajOpen2 .content2::-webkit-scrollbar-thumb {
      background: #FFFFFF;
      border-radius: 20px; }
    .sadrzajOpen2 .content2 .nav-link {
      color: white;
      list-style-type: none;
      text-align: left;
      font-weight: 600;
      font-size: 1.4rem;
      margin-bottom: 1.8rem; }
    .sadrzajOpen2 .content2 .arrowLeft {
      background: transparent !important;
      border: 0;
      outline: none;
      font-size: 60px;
      color: white;
      font-weight: 600;
      padding: 0px; }
      .sadrzajOpen2 .content2 .arrowLeft svg {
        margin-left: 0; }
    .sadrzajOpen2 .content2 .arrowRight {
      background: transparent !important;
      border: 0;
      outline: none;
      font-size: 1.4rem;
      color: white;
      font-weight: 600;
      padding: 0px;
      margin-bottom: 1.3rem;
      display: flex;
      justify-content: space-between;
      width: 100%; }
      .sadrzajOpen2 .content2 .arrowRight svg {
        margin-left: 1rem;
        height: 1.6rem; }
  .sadrzajOpen2 .noContent2 {
    background: #155B98;
    height: 100%;
    padding: 3rem;
    padding-top: 6rem !important;
    position: fixed;
    top: 0;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(350%, 0, 0);
    -moz-transform: translate3d(350%, 0, 0);
    transform: translate3d(350%, 0, 0);
    -webkit-transition: transform 2.5s cubic-bezier(0.25, 1, 0.2, 1);
    -moz-transition: transform 2.5s cubic-bezier(0.25, 1, 0.2, 1);
    transition: transform 2.5s cubic-bezier(0.25, 1, 0.2, 1); }
    .sadrzajOpen2 .noContent2 .nav-link {
      color: white;
      list-style-type: none;
      text-align: left;
      font-weight: 600;
      font-size: 1.4rem;
      margin-bottom: 1.8rem; }
    .sadrzajOpen2 .noContent2 .closeIcon {
      position: absolute;
      top: 2rem;
      right: 2rem;
      background: transparent !important;
      border: 0;
      outline: none !important;
      z-index: 5; }
    .sadrzajOpen2 .noContent2 .arrowLeft {
      background: transparent !important;
      border: 0;
      outline: none;
      font-size: 60px;
      color: white;
      font-weight: 600;
      padding: 0px; }
      .sadrzajOpen2 .noContent2 .arrowLeft svg {
        margin-left: 0; }
    .sadrzajOpen2 .noContent2 .arrowRight {
      background: transparent !important;
      border: 0;
      outline: none;
      font-size: 1.4rem;
      color: white;
      font-weight: 600;
      padding: 0px;
      margin-bottom: 1.3rem;
      display: flex;
      justify-content: space-between;
      width: 100%; }
      .sadrzajOpen2 .noContent2 .arrowRight svg {
        margin-left: 1rem;
        height: 1.6rem; }

.universalPageWrapper h6 {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2.3rem;
  color: #222222;
  margin-top: -0.5rem;
  margin-bottom: 1rem; }

.universalPageWrapper h5 {
  text-align: center;
  font-weight: 700;
  font-size: 1.2rem;
  margin-top: 2rem;
  margin-bottom: 2rem; }

.universalPageWrapper .infoWrap {
  display: block; }
  .universalPageWrapper .infoWrap > div {
    display: flex; }
    .universalPageWrapper .infoWrap > div p {
      margin-bottom: 0.6rem; }

.universalPageWrapper p {
  font-size: 1rem;
  line-height: 1.3rem;
  font-weight: 400;
  color: #222222;
  margin-bottom: 2rem; }
  .universalPageWrapper p span {
    font-weight: 600; }

.universalPageWrapper .smallParahraph {
  font-size: 0.8rem; }

.universalPageWrapper > div li {
  font-size: 0.8rem; }
  .universalPageWrapper > div li:last-child {
    margin-bottom: 2rem; }

.universalPageWrapper .newParagraph {
  margin-bottom: 0.8rem; }

.universalPageWrapper .newParagraphBlue span {
  color: #155B98; }

.universalPageWrapper img {
  border-radius: 20px;
  margin-bottom: 1.5rem;
  width: 100%; }

.universalPageWrapper h3 {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.5rem;
  color: #222222;
  margin-bottom: 1.5rem; }

.universalPageWrapper .redParagraph {
  color: #DA212E;
  margin-bottom: 1rem; }

.universalPageWrapper .animateButton {
  padding: 0.8rem;
  width: 100%; }

.universalPageWrapper input {
  border-radius: 1.25rem;
  height: 2.5rem; }

.universalPageWrapper .shipmentCode {
  background: #155B98;
  border-radius: 1rem;
  height: 2.5rem;
  display: flex;
  text-align: center;
  align-items: center;
  padding-left: 1rem;
  margin-top: 5rem; }
  .universalPageWrapper .shipmentCode h6 {
    font-size: 1rem;
    line-height: 1.2rem;
    color: #FFFFFF;
    margin: 0;
    text-align: center;
    font-weight: 600; }

.universalPageWrapper table {
  border-radius: 20px !important;
  background: #F8F8F8;
  border-collapse: separate;
  border-spacing: 0;
  margin-top: 2rem; }
  .universalPageWrapper table thead {
    background-color: #DA212E; }
    .universalPageWrapper table thead th,
    .universalPageWrapper table thead tr {
      border: 0;
      outline: none;
      color: #FFFFFF;
      font-size: 0.9rem;
      line-height: 1.2rem;
      font-weight: 400; }
      .universalPageWrapper table thead th:first-child,
      .universalPageWrapper table thead tr:first-child {
        border-top-left-radius: 1rem; }
      .universalPageWrapper table thead th:last-child,
      .universalPageWrapper table thead tr:last-child {
        border-top-right-radius: 1rem; }
  .universalPageWrapper table tbody td {
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.2rem;
    color: #222222;
    text-transform: lowercase; }
    .universalPageWrapper table tbody td::first-letter {
      text-transform: capitalize; }

.universalPageWrapper .table2 {
  margin-top: 3rem;
  margin-bottom: 2.5rem; }
  .universalPageWrapper .table2 table {
    border-radius: 0 !important;
    border-bottom-left-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important; }
    .universalPageWrapper .table2 table thead {
      display: table-caption;
      background: #155B98;
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem; }

@media screen and (max-width: 767px) and (min-width: 0px) {
  .universalPageWrapper input {
    margin-bottom: 2rem; }
  .universalPageWrapper .shipmentCode {
    margin-top: 2rem; } }

.contactForm input {
  margin-bottom: 2rem; }

.contactForm ::placeholder {
  color: #9F9F9F !important; }

.contactForm textarea {
  border-radius: 1rem;
  height: 10rem !important; }

.contactForm .animateButton {
  margin-top: 2rem; }
